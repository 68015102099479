<template>
  <div>
    <div class="d-flex flex-wrap nc-criteria-list-container">
      <div
        v-for="(criteria, index) in property?.criteria_list"
        :key="`criteria-${index}`"
        class="d-flex flex-column"
      >
        <library-title tag="h5" :text="criteria.title" :custom-style="customCriteriaTitleStyle"></library-title>
        <library-text
          class="tw-capitalize"
          :text="criteria.value"
        ></library-text>
      </div>
    </div>

    <template v-if="property?.is_rent">
      <library-title
        text="Letting Details"
        class="tw-my-5 tw-font-black"
        tag="h3"
      >
      </library-title>
      <div class="d-flex flex-wrap nc-criteria-list-container">
        <div
          v-for="(criteria, index) in property?.lettings_criteria_list"
          :key="`criteria-${index}`"
          class="d-flex flex-column"
        >
          <library-title tag="h5" :text="criteria.title" :custom-style="customCriteriaTitleStyle"></library-title>
          <library-text
            class="tw-capitalize"
            :text="criteria.value"
          ></library-text>
        </div>
      </div>
    </template>

    <template v-if="property?.flood_list && property?.flood_list.length">
      <library-title text="Flood" class="tw-my-5 tw-font-black" tag="h3">
      </library-title>
      <div class="d-flex flex-wrap nc-criteria-list-container">
        <div
          v-for="(criteria, index) in property?.flood_list"
          :key="`flood-${index}`"
          class="d-flex flex-column"
        >
          <library-title tag="h5" :text="criteria.title" :custom-style="customCriteriaTitleStyle"></library-title>
          <library-text
            class="tw-capitalize"
            :text="criteria.value"
          ></library-text>
        </div>
      </div>
    </template>

    <template
      v-if="
        property?.additional_information_list &&
        property?.additional_information_list.length
      "
    >
      <library-title
        text="Additional Information"
        class="tw-my-5"
        tag="h3"
      >
      </library-title>
      <div class="d-flex flex-wrap nc-criteria-list-container">
        <div
          v-for="(criteria, index) in property?.additional_information_list"
          :key="`flood-${index}`"
          class="d-flex flex-column"
        >
          <library-title tag="h5" :text="criteria.title" :custom-style="customCriteriaTitleStyle"></library-title>
          <library-text
            class="tw-capitalize"
            :text="criteria.value"
          ></library-text>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import type { PropertyItemComponent } from '~/units/properties/types';

export default defineNuxtComponent({
  name: 'LibraryPropertiesPropertyInformation',
  props: {
    property: {
      required: true,
      type: Object as PropType<PropertyItemComponent>,
    },

    customCriteriaTitleStyle: {
      required: false,
      type: String,
    }
  },
});
</script>

<style scoped>
.nc-criteria-list-container {
  grid-column-gap: 50px;
  grid-row-gap: 20px;
}
</style>
